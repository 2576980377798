import {
  Modal,
  Container,
  Row,
  Col,
  Card,
  CloseButton,
} from "react-bootstrap";
// Componentes
import { Loading } from "../../components/Loading/Loading";
// Hooks externos
import { useParams } from "react-router-dom";
// Hooks
import { useGetData } from "../../hooks/useGetData";
import { ReaccionInterface } from "../../interfaces/ReaccionInterface";
import { SistemaInterface } from "../../interfaces/SistemaInterface";
import { CausaReaccionInterface } from "../../interfaces/CausaReaccionInterface";
// Estilos
const classes = require("./ModalDetalleReaccion.module.css").default;

/**
 * ModalDetalleReaccion
 * @description Modal que muestra información detallada de una reacción.
 * @date 09/11/2023.
 * @returns JSX del Modal.
 */
export const ModalDetalleReaccion = ({
  show,
  onHide,
  reaccion_id: propReaccionId, // Prop para el ID de la reacción
}: {
  show: boolean;
  onHide: () => void;
  reaccion_id?: string; // El ID puede venir como prop
}) => {
  /* ----------------------------- Hooks ------------------------------------*/
  // Obtiene el ID de la URL si no se pasa como prop
  const { reaccion_id: urlReaccionId } = useParams();

  // Si no hay un `reaccion_id` pasado como prop, usa el valor de la URL
  const reaccion_id = propReaccionId || urlReaccionId;

  const queryReaccion = useGetData<ReaccionInterface>({
    url: `/reaccion/${reaccion_id}?includeAll=true`,
    name: ["reaccion", `${reaccion_id}`],
  });

  const querySistema = useGetData<SistemaInterface[]>({
    url: `/sistema`,
    name: ["sistema"],
  });

  const queryCausaReaccion = useGetData<CausaReaccionInterface[]>({
    url: `/causa_reaccion?estatus[eq]=1`,
    name: ["causa_reaccion"],
  });

  /* -------------------------------- Render --------------------------------*/
  if (
    querySistema.isLoading ||
    queryReaccion.isLoading ||
    queryCausaReaccion.isLoading
  ) {
    return <Loading />;
  }

  const reaccion = queryReaccion.data;
  const movimiento = reaccion?.movimiento;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      style={{ borderRadius: "15px" }} // Aquí aplicas el border-radius
    >
      <Modal.Header className={classes.modalHeader}>
        <Modal.Title className={classes.modalTitle}>
          CIO/R/{reaccion?.id}-
          {new Date(reaccion?.created_at ?? "").getFullYear()}
        </Modal.Title>
        <CloseButton aria-label="Close" variant="white" onClick={onHide} />
      </Modal.Header>

      <Modal.Body className={classes.modalBody}>
        <Container>
          <Card className="mb-4 shadow-sm">
            <Card.Header className={classes.cardHeader}>
              <h5 className="mb-0">Reacción</h5>
            </Card.Header>
            <Card.Body className={classes.cardBody}>
              <Row>
                <Col>
                  <p>
                    <strong>Causa de la reacción:</strong>{" "}
                    {reaccion?.causa_reaccion?.nombre || "N/A"}
                  </p>
                  <p>
                    <strong>Lugar de reacción:</strong>{" "}
                    {reaccion?.lugar_reaccion || "N/A"}
                  </p>
                  <p>
                    <strong>Coordenadas de reacción:</strong>{" "}
                    {`${reaccion?.latitud || "N/A"}, ${
                      reaccion?.longitud || "N/A"
                    }`}
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="shadow-sm">
            <Card.Header className={classes.cardHeader}>
              <h5 className="mb-0">Movimiento</h5>
            </Card.Header>
            <Card.Body className={classes.cardBody}>
              <Row>
                <Col md={6}>
                  <p>
                    <strong>Marca:</strong> {movimiento?.marca || "N/A"}
                  </p>
                  <p>
                    <strong>Placa:</strong> {movimiento?.placa || "N/A"}
                  </p>
                  <p>
                    <strong>Modelo:</strong>{" "}
                    {movimiento?.modelo?.toString() || "N/A"}
                  </p>
                  <p>
                    <strong>Color:</strong> {movimiento?.color || "N/A"}
                  </p>
                  <p>
                    <strong>Económico:</strong> {movimiento?.economico || "N/A"}
                  </p>
                  <p>
                    <strong>Caja:</strong> {movimiento?.caja || "N/A"}
                  </p>
                  <p>
                    <strong>Placa de la caja:</strong>{" "}
                    {movimiento?.placa_caja || "N/A"}
                  </p>


                  <p>
                    <strong>Color de la caja:</strong>{" "}
                    {movimiento?.color_caja || "N/A"}
                  </p>

                  <p>
                    <strong>Modelo de la caja:</strong>{" "}
                    {movimiento?.modelo_caja || "N/A"}
                  </p>
                  <p>
                    <strong>Economico de la caja:</strong>{" "}
                    {movimiento?.economico_caja || "N/A"}
                  </p>
                  <p>
                    <strong>Capacidad de la caja:</strong>{" "}
                    {movimiento?.capacidad_caja || "N/A"}
                  </p>
                </Col>
                <Col md={6}>
                  <p>
                    <strong>Contenedor 1:</strong>{" "}
                    {movimiento?.contenedor1 || "N/A"}
                  </p>
                  <p>
                    <strong>Contenedor 2:</strong>{" "}
                    {movimiento?.contenedor2 || "N/A"}
                  </p>
                  <p>
                    <strong>Operador:</strong> {movimiento?.operador || "N/A"}
                  </p>
                  <p>
                    <strong>Medio:</strong> {movimiento?.medio || "N/A"}
                  </p>
                  <p>
                    <strong>Origen:</strong> {movimiento?.origen || "N/A"}
                  </p>
                  <p>
                    <strong>Destino:</strong> {movimiento?.destino || "N/A"}
                  </p>
                  <p>
                    <strong>Transportista:</strong>{" "}
                    {movimiento?.transportista || "N/A"}
                  </p>
                  <p>
                    <strong>Central:</strong> {movimiento?.plataforma || "N/A"}
                  </p>
                  <p>
                    <strong>Estatus de carga:</strong>{" "}
                    {movimiento?.estatus_carga || "N/A"}
                  </p>
                  <p>
                    <strong>Sistema:</strong>{" "}
                    {movimiento?.sistema?.nombre || "N/A"}
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
