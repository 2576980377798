//Componentes externos
import { Button, Container, Form } from 'react-bootstrap';
//Componentes
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { Loading } from '../../components/Loading/Loading';
import { CustomSelect } from '../../components/CustomSelect/CustomSelect';
//Hooks externos
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
//Hooks
import { useMutationData } from '../../hooks/useMutationData';
import { useGetData } from '../../hooks/useGetData';
import { SistemaInterface } from '../../interfaces/SistemaInterface';
import { CausaReaccionInterface } from '../../interfaces/CausaReaccionInterface';
//Estilo
const classes = require('./ReaccionCrearScreen.module.css').default;

/**
 * ReaccionCrearScreen
 * @description: Pantalla para crear una reacción
 * @date 09/11/2023.
 * @returns JSX del Screen.
 */
export const ReaccionCrearScreen = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Hook del formulario
  const { control, handleSubmit } = useForm();
  //Hook para realizar la petición
  const mutateReaccion = useMutationData({ url: '/reaccion' });
  //Hook para obtener el registro
  const querySistema = useGetData<SistemaInterface[]>({
    url: `/sistema`,
    name: ['sistema'],
  });
  //Hook para obtener el registro
  const queryCausaReaccion = useGetData<CausaReaccionInterface[]>({
    url: `/causa_reaccion?estatus[eq]=1`,
    name: ['causa_reaccion'],
  });
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (
    mutateReaccion.isPending ||
    querySistema.isLoading ||
    queryCausaReaccion.isLoading
  ) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="p-4">
      <Form>
        <div className="row">
          {/* Subtítulo */}
          <div className="row mt-3">
            <h2 className={classes.subtitulo}>Reacción</h2>
          </div>
          {/* Causa reaccion */}
          <CustomSelect
            control={control}
            name="reaccion.causa_reaccion_id"
            label="Causa de la reacción"
            valueField={'id'}
            labelField={'nombre'}
            data={queryCausaReaccion.data}
            rules={{
              required: 'Se requiere elegir una causa de reacción',
            }}
          />
          {/* Lugar reacción */}
          <CustomInput
            control={control}
            name="reaccion.lugar_reaccion"
            label="Lugar de reacción"
            rules={{
              maxLength: {
                value: 256,
                message:
                  'El lugar de reacción tiene que ser menor a 256 caracteres',
              },
            }}
          />
          {/* Coordenadas reacción */}
          <CustomInput
            control={control}
            name="reaccion.coordenadas_reaccion"
            label="Coordenadas de reacción"
            rules={{
              required: 'Se requiere de unas coordenadas de reacción',
              pattern: {
                value:
                  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
                message:
                'Las coordenadas de la reacción no tienen un formato correcto',
              },
            }}
          />
        </div>
        <div className="row">
          {/* Subtítulo */}
          <div className="row mt-3">
            <h2 className={classes.subtitulo}>Movimiento</h2>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 px-4">
              {/* Marca */}
              <CustomInput
                control={control}
                name="movimiento.marca"
                label="Marca"
                rules={{
                  maxLength: {
                    value: 32,
                    message: 'La marca tiene que ser menor a 32 caracteres',
                  },
                }}
              />
              {/* Placa */}
              <CustomInput
                control={control}
                name="movimiento.placa"
                label="Placa"
                rules={{
                  maxLength: {
                    value: 16,
                    message: 'La placa tiene que ser menor a 16 caracteres',
                  },
                }}
              />
              {/* Modelo */}
              <CustomInput
                control={control}
                name="movimiento.modelo"
                label="Modelo"
                tipo="number"
                rules={{
                  min: {
                    value: 0,
                    message: 'El modelo tiene que ser positivo',
                  },
                }}
              />
              {/* Color */}
              <CustomInput
                control={control}
                name="movimiento.color"
                label="Color"
                rules={{
                  maxLength: {
                    value: 128,
                    message: 'El color tiene que ser menor a 128 caracteres',
                  },
                }}
              />
              {/* Económico */}
              <CustomInput
                control={control}
                name="movimiento.economico"
                label="Económico"
                rules={{
                  maxLength: {
                    value: 32,
                    message: 'El económico tiene que ser menor a 32 caracteres',
                  },
                }}
              />
              {/* Caja */}
              <CustomInput
                control={control}
                name="movimiento.caja"
                label="Caja"
                rules={{
                  maxLength: {
                    value: 128,
                    message: 'La caja tiene que ser menor a 128 caracteres',
                  },
                }}
              />
              {/* Placa Caja */}
              <CustomInput
                control={control}
                name="movimiento.placa_caja"
                label="Placa caja"
                rules={{
                  maxLength: {
                    value: 16,
                    message:
                      "La placa de la caja tiene que ser menor a 16 caracteres",
                  },
                }}
              />
              {/* Economico Caja */}
              <CustomInput
                control={control}
                name="movimiento.economico_caja"
                label="Economico caja"
                rules={{
                  maxLength: {
                    value: 32,
                    message:
                      "El economico caja tiene que ser menor a 32 caracteres",
                  },
                }}
              />
              {/* Color Caja */}
              <CustomInput
                control={control}
                name="movimiento.color_caja"
                label="Color caja"
                rules={{
                  maxLength: {
                    value: 128,
                    message:
                      "El color de la caja tiene que ser menor a 128 caracteres",
                  },
                }}
              />
              {/* Modelo Caja */}
              <CustomInput
                control={control}
                name="movimiento.modelo_caja"
                label="Modelo caja"
                tipo="number"
                rules={{
                  min: {
                    value: 0,
                    message: "El modelo tiene que ser positivo",
                  },
                }}
              />
              {/* Capacidad Caja */}
              <CustomInput
                control={control}
                name="movimiento.capacidad_caja"
                label="Capacidad caja"
                rules={{
                  maxLength: {
                    value: 32,
                    message:
                      "La capacidad de la caja tiene que ser menor a 32 caracteres",
                  },
                }}
              />
            </div>
            <div className="col-md-6 col-sm-12 px-4">
              {/* Contenedor 1 */}
              <CustomInput
                control={control}
                name="movimiento.contenedor1"
                label="Contenedor 1"
                rules={{
                  maxLength: {
                    value: 128,
                    message:
                    'El contenedor tiene que ser menor a 128 caracteres',
                  },
                }}
              />
              {/* Contenedor 2 */}
              <CustomInput
                control={control}
                name="movimiento.contenedor2"
                label="Contenedor 2"
                rules={{
                  maxLength: {
                    value: 128,
                    message:
                    'El contenedor tiene que ser menor a 128 caracteres',
                  },
                }}
              />
              {/* Operador */}
              <CustomInput
                control={control}
                name="movimiento.operador"
                label="Operador"
                rules={{
                  maxLength: {
                    value: 128,
                    message: 'El operador tiene que ser menor a 128 caracteres',
                  },
                }}
              />
              {/* Medio */}
              <CustomInput
                control={control}
                name="movimiento.medio"
                label="Medio"
                rules={{
                  maxLength: {
                    value: 32,
                    message: 'El medio tiene que ser menor a 32 caracteres',
                  },
                }}
              />
              {/* Origen */}
              <CustomInput
                control={control}
                name="movimiento.origen"
                label="Origen"
                rules={{
                  maxLength: {
                    value: 256,
                    message: 'El origen tiene que ser menor a 256 caracteres',
                  },
                }}
              />
              {/* Destino */}
              <CustomInput
                control={control}
                name="movimiento.destino"
                label="Destino"
                rules={{
                  maxLength: {
                    value: 256,
                    message: 'El operador tiene que ser menor a 256 caracteres',
                  },
                }}
              />
              {/* Transportista */}
              <CustomInput
                control={control}
                name="movimiento.transportista"
                label="Transportista"
                rules={{
                  required: 'Se requiere de un transportista',
                  maxLength: {
                    value: 256,
                    message:
                      'El transportista tiene que ser menor a 256 caracteres',
                  },
                }}
              />
              {/* Plataforma/Central */}
              <CustomInput
                control={control}
                name="movimiento.plataforma"
                label="Central"
                rules={{
                  required: 'Se requiere de una central',
                  maxLength: {
                    value: 32,
                    message: 'La central tiene que ser menor a 32 caracteres',
                  },
                }}
              />
              {/* Estatus de carga */}
              <CustomSelect
                control={control}
                name="movimiento.estatus_carga"
                label="Estatus de carga"
                labelField={'label'}
                valueField={'value'}
                returnFormat={'label'}
                data={[
                  { value: 1, label: 'Sin carga' },
                  { value: 2, label: 'Con carga' },
                ]}
                rules={{
                  required: 'Se requiere de un estatus de carga',
                }}
              />
              {/* Sistema */}
              <CustomSelect
                control={control}
                name="movimiento.sistema_id"
                label="Sistema"
                valueField={'id'}
                labelField={'nombre'}
                data={querySistema.data}
                rules={{
                  required:
                    'Se requiere elegir un sistema de origen de la reacción',
                }}
              />
            </div>
          </div>
        </div>
        <Button
          className={`${classes.boton} mt-3`}
          onClick={handleSubmit((data: any) => {
            mutateReaccion.mutate(
              {
                data: data,
                tipo: 'post',
              },
              {
                onSuccess: () => {
                  //Se redirige en caso de exito
                  navigate('/reaccion/porAsignar');
                },
              }
            );
          })}
        >
          Guardar
        </Button>
      </Form>
    </Container>
  );
};
