import React from "react";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GenerarContrasena: React.FC<{
  control: any;
  setValue: (name: string, value: any) => void;
}> = ({ control, setValue }) => {
  const generatePassword = () => {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*";
    let password = "";
    const length = 13; // Longitud mínima de la contraseña

    // crypto.getRandomValues() genera números aleatorios de manera segura
    const array = new Uint32Array(length);
    window.crypto.getRandomValues(array);

    for (let i = 0; i < length; i++) {
      const randomIndex = array[i] % charset.length;
      password += charset[randomIndex];
    }
    return password;
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    setValue("contrasena", newPassword); // Actualiza el valor del campo "contrasena"
  };

  return (
    <div>
      <CustomInput
        control={control}
        name="contrasena"
        label="Contraseña"
        tipo="password"
        showEye={true}
        autoComplete="off"
      />
      <div className="d-flex justify-content-end mt-2">
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault(); 
            handleGeneratePassword();
          }}
          style={{
            color: "#2d3182", 
            textDecoration: "underline", 
            background: "transparent", 
            border: "none",
            padding: 0, 
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faKey} className="me-2" />
          Generar Contraseña
        </button>
      </div>
    </div>
  );
};

export default GenerarContrasena;
