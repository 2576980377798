import { useContext } from "react";
import axios from "axios";
import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "../context/AuthContext";
import { API } from "../global/global";
import { toast } from "react-toastify";

// Props
interface UseMutationFileProps {
  url: string;
  reloads?: QueryKey[];
}
interface MutationData {
  id?: number | string;
  data: File[]; // Utilizamos File para manejar archivos en React Web
  type: "post" | "delete";
}

/**
 * useMutationFile
 * @description: Hook para realizar peticiones post, put, patch, delete con archivos.
 * @date 07/12/2024
 * @param {UseMutationFileProps} { url, reload }
 * @returns Hook para ejecutar las peticiones.
 */
export const useMutationFile = ({ url, reloads }: UseMutationFileProps) => {
  /* ----------------------------- Hooks ------------------------------------*/
  const authContext = useContext(AuthContext);
  const queryClient = useQueryClient();

  /* ------------------------------ Funciones -------------------------------*/
  const sendRequest = async ({ id, data, type }: MutationData) => {
    const formData = new FormData();
    data.forEach((file) => {
      if (url === "/evento_images") {
        formData.append("images[]", file); // Para subir imágenes
      } else if (url === "/evento_audios") {
        formData.append("audios[]", file); // Para subir audios
      } else {
        formData.append("documents[]", file); // Para documentos
      }
    });
    

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authContext.token}`,
      },
    };

    const endpoint = id !== undefined ? `${API + url}/${id}` : API + url;

    switch (type) {
      case "delete":
        return axios.delete(endpoint, options);
      case "post":
      default:
        return axios.post(endpoint, formData, options);
    }
  };

  /* ------------------------------ Return ----------------------------------*/
  return useMutation({
    mutationFn: ({ id, data, type }: MutationData) =>
      sendRequest({ id, data, type }),
    onSuccess: (data) => {
      toast.success(data?.data?.message ?? "¡Operación exitosa! 🎉");
      // Invalida las queries relacionadas
      for (let reload of reloads ?? []) {
        queryClient.invalidateQueries({ queryKey: reload });
      }
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ??
          "Ocurrió un error inesperado. Por favor, inténtalo nuevamente."
      );
    },
  });
};
