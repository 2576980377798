import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faFileAudio,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import FilePreview from "./FilePreview/FilePreview";
import FileModal from "./FileModal/FileModal";

interface FilePickerProps {
  eventoId: string;
  fileType: "image" | "audio" | "document"; // Tipo de archivo que aceptará
  onFilesUpload?: (files: File[]) => void;
}

const FilePicker: React.FC<FilePickerProps> = ({ fileType, onFilesUpload }) => {
  const [files, setFiles] = useState<{ file: File; url: string }[]>([]);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (selectedFiles) {
      const newFiles = Array.from(selectedFiles)
        .filter((file) => {
          const isImage = file.type.startsWith("image/");
          //const isAudio = file.type.startsWith("audio/");
          //const isVideo = file.type.startsWith("video/");
          //const isDocument = !isImage && !isAudio && !isVideo; // Todo menos audios, videos o imagenes
          //const isDocument = !isImage; // Todo menos audios, videos o imagenes
          const isDocument =
            file.type === "application/pdf" ||
            file.type.startsWith("audio/") ||
            file.type.startsWith("video/");

          // Filtrar según el tipo de archivo deseado
          //if (fileType === "image" && !(isImage || isVideo)) return false; // Permitir imágenes y videos
          if (fileType === "image" && !isImage) return false; // Permitir SOLO imágenes
          //if (fileType === "audio" && !isAudio) return false;
          if (fileType === "document" && !isDocument) return false;
          return true;
        })
        .map((file) => ({
          file,
          url: URL.createObjectURL(file),
        }));

      // Si no hay archivos válidos (es decir, si no coinciden con el tipo permitido)
      if (newFiles.length === 0) {
        toast.warning(
          `Por favor, selecciona archivos válidos de tipo ${
            fileType === "image"
              ? "imagen"
              : fileType === "document"
              ? "pdf, audio o video"
              : fileType
          }.`
        );
        return;
      }

      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      if (onFilesUpload) {
        onFilesUpload([
          ...files.map((file) => file.file),
          ...newFiles.map((file) => file.file),
        ]);
      }
      // Restablece el valor del input
      e.target.value = "";
    }
  };

  const handleRemoveFile = (url: string) => {
    const updatedFiles = files.filter((file) => file.url !== url);
    setFiles(updatedFiles);

    // Revoca el URL del objeto para liberar memoria
    const removedFile = files.find((file) => file.url === url);
    if (removedFile) {
      URL.revokeObjectURL(removedFile.url);
    }

    if (onFilesUpload) {
      onFilesUpload(updatedFiles.map((file) => file.file));
    }

    if (selectedFile === url) {
      setSelectedFile(null);
    }
  };

  // Determina el valor de `accept` según el tipo de archivo
  const getAcceptType = () => {
    if (fileType === "image") {
      return "image/*,video/*"; // Permite imágenes y videos
    } else if (fileType === "audio") {
      return "audio/*"; // Permite solo audios
    } else if (fileType === "document") {
      return "application";
    }
    return "";
  };

  // Determina el ícono según el tipo de archivo
  const getFileIcon = () => {
    if (fileType === "image") {
      return faImage; // Ícono para imágenes y videos
    } else if (fileType === "audio") {
      return faFileAudio; // Ícono para audio
    } else {
      return faFileAlt; // Ícono para documentos
    }
  };

  return (
    <div>
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        style={{ display: "none" }}
        id={`${fileType}FileInput`}
        accept={getAcceptType()} // Establece los tipos de archivo permitidos según el fileType
      />

      <label htmlFor={`${fileType}FileInput`} style={{ cursor: "pointer" }}>
        <FontAwesomeIcon
          icon={getFileIcon()} // Establece el ícono dinámicamente
          title={`Seleccionar ${
            fileType === "image"
              ? "imágenes o videos"
              : fileType === "audio"
              ? "audios"
              : "documentos"
          }`}
          style={{ fontSize: "30px", color: "#2d3182", marginTop: "10px" }}
        />
      </label>

      <FilePreview
        previewUrls={files.map((file) => file.url)}
        setSelectedDocument={setSelectedFile}
        files={files} // Pasa la propiedad 'files'
      />

      <FileModal
        selectedDocument={selectedFile}
        previewUrls={files.map((file) => file.url)}
        setSelectedDocument={setSelectedFile}
        onRemoveDocument={handleRemoveFile}
        files={files}
      />
    </div>
  );
};

export default FilePicker;
