//React
import { useMemo } from 'react';
//Componentes externos
import { Container } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
//Componentes
import CustomTable from '../../components/CustomTable/CustomTable';
import { Loading } from '../../components/Loading/Loading';
//Hooks
import { useGetData } from '../../hooks/useGetData';
//Interfaces
import { RolInterface } from '../../interfaces/RolInterface';
//Estilo
const classes = require('./RolListaScreen.module.css').default;

/**
 * RolListaScreen
 * @description: Pantalla que muestra la lista de roles
 * @date 16/10/2023.
 * @returns JSX del Screen.
 */
export const RolListaScreen = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Se encarga de traer la información
  const queryRol = useGetData<RolInterface[]>({ url: '/rol', name: ['rol'] });
  /* ------------------------------ Funciones -------------------------------*/
  const columns = useMemo<ColumnDef<RolInterface>[]>(
    () => [
      {
        header: '#',
        accessorKey: 'id',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'nombre',
        header: () => 'Nombre',
      },
      {
        id: 'estatus',
        header: 'Estatus',
        accessorFn: row => (row.estatus === 1 ? 'ACTIVO' : 'ELIMINADO'),
        cell: info => info.getValue(),
      },
    ],
    []
  );
  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (queryRol.isLoading) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="p-4">
      {/* Subtítulo */}
      <div className="row mt-3">
        <h2 className={classes.subtitulo}>Roles</h2>
      </div>
      <CustomTable
        {...{
          data: queryRol.data,
          columns,
        }}
      />
    </Container>
  );
};
