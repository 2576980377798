//Componentes externos
import { Button, Container, Form } from "react-bootstrap";
//Componentes
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { Loading } from "../../components/Loading/Loading";
import { ModalDetalleReaccion } from "../../components/ModalDetalleReaccion/ModalDetalleReaccion";
import FileModal from "../../components/Multimedia/FileModal/FileModal";
//Hooks externos
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
//Hooks
import { useMutationData } from "../../hooks/useMutationData";
import { useGetData } from "../../hooks/useGetData";
//Constantes
import { API } from "../../global/global";
//Contexts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faFileArchive,
  faHeadphones,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { EventoInterface } from "../../interfaces/EventoInterface";
import { AuthContext } from "../../context/AuthContext";
//Estilo
const classes = require("./EventoEditarScreen.module.css").default;

/**
 * EventoEditarScreen
 * @description: Pantalla para editar un evento
 * @date 10/11/2023.
 * @returns JSX del Screen.
 */
export const EventoEditarScreen = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  const { evento_id } = useParams();
  //Hook del formulario
  const { control, handleSubmit } = useForm();
  //Hook para realizar la petición
  const mutateEvento = useMutationData({
    url: `/evento/${evento_id}`,
    reload: ["evento", `${evento_id}`],
  });
  //Hook para obtener el registro
  const queryEvento = useGetData<EventoInterface>({
    url: `/evento/${evento_id}?includeAll=true`,
    name: ["evento", `${evento_id}`],
  });
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  const setModalType = useState<string>("")[1];
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isImageVisible, setIsImageVisible] = useState<boolean>(false);
  const [isDocumentVisible, setIsDocumentVisible] = useState<boolean>(false);
  const [isAudioVisible, setIsAudioVisible] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);
  const authContext = useContext(AuthContext);

  // Función para mapear los archivos a un formato adecuado para su visualización
  const mapFiles = (filesArray: string[] = []) => {
    return filesArray.map((file) => ({
      file: new File([], file),
      url: `${API.replace("api", "storage")}/evento/${file}`,
    }));
  };

  // Mapea las imágenes, documentos y audios a su formato adecuado
  const imagesAndVideos = mapFiles(queryEvento.data?.images);
  const documents = mapFiles(queryEvento.data?.documents);
  const audios = mapFiles(queryEvento.data?.audios);
  // Función para manejar la selección de un archivo (imagen, documento o audio)
  const handleMediaClick = (
    index: number,
    type: "image" | "document" | "audio"
  ) => {
    
    setModalType(type);
    setCurrentIndex(index);

    // Controla la visibilidad del modal según el tipo de archivo
    if (type === "image") {
      setIsImageVisible(true);
      setIsDocumentVisible(false);
      setIsAudioVisible(false);
    } else if (type === "document") {
      setIsImageVisible(false);
      setIsDocumentVisible(true);
      setIsAudioVisible(false);
    } else if (type === "audio") {
      setIsImageVisible(false);
      setIsDocumentVisible(false);
      setIsAudioVisible(true);
    }
  };

  // Verifica si el evento pertenece al usuario actual
  const esEventoPropio = () => {
    if (queryEvento.data && authContext.usuario) {
      const evento = queryEvento.data;
      const usuario = authContext.usuario;

      //console.log("Evento usuario_id:", evento.usuario?.id);
      //console.log("Usuario usuario_id:", usuario.id);
      //console.log("Evento usuario_externo_id:", evento.usuario_externo_id);
      //console.log("Usuario usuario_externo_id:", usuario.usuario_externo_id);

      // Verificar si el usuario_id coincide (si está presente en el evento)
      const mismoUsuarioId =
        evento.usuario?.id !== undefined &&
        usuario.id !== undefined &&
        evento.usuario.id === usuario.id;

      // Convertir usuario_externo_id a número si es una cadena
      const usuarioExternoIdUsuario = usuario.usuario_externo_id
        ? Number(usuario.usuario_externo_id)
        : null;

      // Verificar si el usuario_externo_id coincide (si está presente en el evento)
      const mismoUsuarioExternoId =
        evento.usuario_externo_id !== null &&
        usuarioExternoIdUsuario !== null &&
        evento.usuario_externo_id === usuarioExternoIdUsuario;

      // Determinar si el evento es propio
      if (
        evento.usuario?.id !== undefined &&
        evento.usuario_externo_id !== null
      ) {
        // Si el evento tiene ambos IDs, ambos deben coincidir
        return mismoUsuarioId && mismoUsuarioExternoId;
      } else if (
        evento.usuario?.id !== undefined &&
        evento.usuario_externo_id === null
      ) {
        // Si el evento tiene usuario_id pero no tiene usuario_externo_id, solo comparar usuario_id
        return mismoUsuarioId;
      } else if (evento.usuario_externo_id !== null) {
        // Si el evento solo tiene usuario_externo_id, debe coincidir
        return mismoUsuarioExternoId;
      }

      // Si no tiene ningún ID, no es editable
      return false;
    }
    return false; // Por defecto, no es propio si no hay datos
  };

  //Return loading si aún se está haciendo la petición
  if (queryEvento.isLoading || mutateEvento.isPending) {
    return <Loading />;
  }

  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (queryEvento.isLoading || mutateEvento.isPending) {
    return <Loading />;
  }

  // Función para renderizar la sección de medios (imágenes, documentos o audios)
  const renderMediaSection = (files: any[], type: string) => (
    <>
      {files.length > 0 && (
        <div>
          <h3 className="mt-3 text-start">
            {type === "image"
              ? "Imágenes y Videos"
              : type === "document"
              ? "Documentos"
              : "Audios"}
          </h3>
          <div className={`${classes.document_grid}`}>
            {files.map((file, index) => {
              const fileExtension = file.url.split(".").pop();
              const isVideo =
                type === "image" &&
                [
                  "mp4",
                  "mpeg",
                  "mkv",
                  "avi",
                  "mov",
                  "webm",
                  "flv",
                  "wmv",
                  "3gp",
                  "ogg",
                  "ts",
                  "avi",
                ].includes((fileExtension?.toLowerCase() || "").trim());

              return (
                <div
                  key={`${type}-${index}`}
                  onClick={() =>
                    handleMediaClick(
                      index,
                      type as "audio" | "image" | "document"
                    )
                  }
                >
                  {/* Si es una imagen, mostramos la imagen o un indicador de video */}
                  {type === "image" ? (
                    isVideo ? (
                      <div className={`${classes.image_card}`}>
                        <span className={`${classes.video_icon}`}>
                          <FontAwesomeIcon icon={faPlayCircle} />{" "}
                          {/* Ícono de video */}
                        </span>
                        <span className={`${classes.file_extension}`}>
                          {fileExtension?.toUpperCase()}{" "}
                          {/* Extensión del archivo */}
                        </span>
                      </div>
                    ) : (
                      <img
                        src={file.url}
                        alt={`${type}-${index}`}
                        loading="lazy"
                        className={`${classes.image_card}`}
                      />
                    )
                  ) : type === "document" || type === "audio" ? (
                    // Para documentos o audios, mostramos un cuadro con la extensión del archivo
                    <div className={`${classes.image_card}`}>
                      <span className={`${classes.video_icon}`}>
                        <FontAwesomeIcon
                          icon={
                            type === "document" ? faFileArchive : faHeadphones
                          }
                        />
                      </span>
                      <span className={`${classes.file_extension}`}>
                        {fileExtension?.toUpperCase()}
                      </span>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
          {/* Modal para ver los archivos seleccionados */}
          <FileModal
            selectedDocument={files[currentIndex]?.url || null}
            previewUrls={files.map((file) => file.url)}
            setSelectedDocument={(url) => {
              const index = files.findIndex((file) => file.url === url);
              setCurrentIndex(index);
            }}
            files={files}
            isVisible={
              type === "image"
                ? isImageVisible
                : type === "document"
                ? isDocumentVisible
                : isAudioVisible
            }
          />
        </div>
      )}
    </>
  );

  //Return principal
  return (
    <Container className="p-4">
      <Form>
        <div className="row">
          <div className="col-12">
            {/* Comentario */}
            <CustomTextArea
              control={control}
              name="comentario"
              label="Comentario"
              value={queryEvento.data?.comentario}
              rules={{
                required: "Se requiere de un comentario",
                maxLength: {
                  value: 512,
                  message: "El comentario tiene que ser menor a 512 caracteres",
                },
              }}
            />
            {/* Coordenadas evento */}
            <CustomInput
              control={control}
              name="coordenadas_evento"
              label="Coordenadas del evento"
              value={`${
                queryEvento.data?.latitud && queryEvento.data?.longitud
                  ? queryEvento.data?.latitud +
                    ", " +
                    queryEvento.data?.longitud
                  : ""
              }`}
              rules={{
                pattern: {
                  value:
                    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
                  message:
                    "Las coordenadas del evento no tienen un formato correcto",
                },
              }}
            />
            {authContext.rol === "Invitado" && (
              <>
                {/* Usuario externo id */}
                <CustomInput
                  control={control}
                  name="usuario_externo_id"
                  value={authContext.usuario?.usuario_externo_id}
                  tipo={"hidden"}
                />
                {/* Usuario externo nombre */}
                <CustomInput
                  control={control}
                  name="usuario_externo_nombre"
                  value={authContext.usuario?.usuario_externo_nombre}
                  tipo={"hidden"}
                />
              </>
            )}
          </div>
        </div>

        {renderMediaSection(imagesAndVideos, "image")}
        {renderMediaSection(documents, "document")}
        {renderMediaSection(audios, "audio")}

        {/* Mostrar el botón de Guardar solo si el evento es propio */}
        {esEventoPropio() && (
          <Button
            className={`${classes.boton} mt-3`}
            onClick={handleSubmit((data: any) => {
              mutateEvento.mutate(
                {
                  data: data,
                  tipo: "patch",
                },
                {
                  onSuccess: () => {
                    //Se redirige en caso de exito
                    navigate(`/evento/lista/${queryEvento.data?.reaccion?.id}`);
                  },
                }
              );
            })}
          >
            Guardar
          </Button>
        )}
        <Button
          title="Detalles de la reacción"
          className={`${classes.boton} ${classes.fixedButton}`}
          onClick={() => setModalVisible(true)}
        >
          <FontAwesomeIcon icon={faCircleInfo} />
        </Button>
      </Form>
      <ModalDetalleReaccion
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        reaccion_id={`${queryEvento.data?.reaccion?.id}`}
      />
    </Container>
  );
};
