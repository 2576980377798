//Componentes externos
import { Button, Container, Form } from 'react-bootstrap';
//Componentes
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { Loading } from '../../components/Loading/Loading';
import GenerarContrasena from "../../components/GenerarContrasena/GenerarContrasena";
//Hooks externos
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
//Hooks
import { useMutationData } from '../../hooks/useMutationData';
import { CustomSelect } from '../../components/CustomSelect/CustomSelect';
import { useGetData } from '../../hooks/useGetData';
import { RolInterface } from '../../interfaces/RolInterface';
//Estilo
const classes = require('./UsuarioCrearScreen.module.css').default;

/**
 * UsuarioCrearScreen
 * @description: Pantalla para crear un usuario
 * @date 10/10/2023.
 * @returns JSX del Screen.
 */
export const UsuarioCrearScreen = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Hook del formulario
  const { control, setValue, handleSubmit } = useForm();
  //Hook para realizar la petición
  const mutateUsuario = useMutationData({ url: '/usuario' });
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  //Se encarga de traer la información
  const queryRoles = useGetData<RolInterface[]>({
    url: `/rol?estatus[eq]=1`,
    name: ['rol'],
  });
  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (queryRoles.isLoading || mutateUsuario.isPending) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="p-4">
      <Form>
        <div className="row gx-5">
          <div className="col-md-6 col-sm-12">
            {/* Nombre */}
            <CustomInput
              control={control}
              name="nombre"
              label="Nombre"
              rules={{
                required: 'Se requiere de un nombre',
                maxLength: {
                  value: 32,
                  message: 'El nombre tiene que ser menor a 32 caracteres',
                },
              }}
            />
            {/* Apellido materno */}
            <CustomInput
              control={control}
              name="apellido_materno"
              label="Apellido materno"
              rules={{
                maxLength: {
                  value: 32,
                  message:
                    'El apellido materno tiene que ser menos a 32 caracteres',
                },
              }}
            />
             {/* Apellido paterno */}
             <CustomInput
              control={control}
              name="apellido_paterno"
              label="Apellido paterno"
              rules={{
                required: 'Se requiere de un apellido paterno',
                maxLength: {
                  value: 32,
                  message:
                    'El apellido paterno tiene que ser menos a 32 caracteres',
                },
              }}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            {/* Rol */}
            <CustomSelect
              control={control}
              name="rol_id"
              label="Rol"
              valueField={'id'}
              labelField={'nombre'}
              data={queryRoles.data}
              rules={{
                required: 'Se requiere de un rol',
              }}
            />
            {/* Correo */}
            <CustomInput
              control={control}
              name="correo"
              label="Correo"
              rules={{
                required: 'Se requiere de un correo',
                maxLength: {
                  value: 128,
                  message: 'El correo tiene que ser menor a 128 caracteres',
                },
              }}
            />
            {/* Contraseña */}
            <GenerarContrasena control={control} setValue={setValue} />
          </div>
        </div>
        <Button
          className={`${classes.boton} mt-3`}
          onClick={handleSubmit((data: any) => {
            mutateUsuario.mutate(
              {
                data: data,
                tipo: 'post',
              },
              {
                onSuccess: () => {
                  //Se redirige en caso de exito
                  navigate('/usuario');
                },
              }
            );
          })}
        >
          Guardar
        </Button>
      </Form>
    </Container>
  );
};
