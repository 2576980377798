import React from "react";

interface FilePreviewProps {
  previewUrls: string[];
  setSelectedDocument: React.Dispatch<React.SetStateAction<string | null>>;
  files: { file: File; url: string }[]; 
}

const FilePreview: React.FC<FilePreviewProps> = ({
  previewUrls,
  setSelectedDocument,
  files,
}) => {
  return (
    <>
      {previewUrls.length > 0 && (
        <div
          className="audio-preview-container"
          style={{
            display: "grid",
            gridTemplateAreas:
              previewUrls.length === 3
                ? `"a b"
                   "c c"` // Diseño específico para 3 elementos
                : previewUrls.length === 4
                ? `"a b"
                   "c d"` // Diseño 2x2 para 4 elementos
                : previewUrls.length > 4
                ? `"a b"
                   "c more"` // Diseño para más de 4 elementos
                : `"a b"`, // Para 2 archivos, 1 fila con 2 columnas
            gap: "5px",
            padding: "5px",
            maxWidth: "300px", // Ajusta el ancho total del contenedor
          }}
        >
          {previewUrls.slice(0, 4).map((url, index) => {
            const file = files[index];
            const fileExtension = file.file.name.split(".").pop();
            const size = "120px";
            const gridArea =
              index === 0
                ? "a"
                : index === 1
                ? "b"
                : index === 2
                ? "c"
                : index === 3 && previewUrls.length === 4
                ? "d"
                : index === 3
                ? "more"
                : "";

            const isImage = ["jpg", "jpeg", "png", "gif", "webp"].includes(
              fileExtension?.toLowerCase() || ""
            );

            if (index === 3 && previewUrls.length > 4) {
              return (
                <div
                  key={index}
                  style={{
                    gridArea: "more",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    overflow: "hidden",
                    backgroundColor: "#f0f0f0",
                  }}
                  onClick={() => setSelectedDocument(previewUrls[3])}
                >
                  +{previewUrls.length - 4} más
                </div>
              );
            }

            return (
              <div
                key={index}
                style={{
                  gridArea,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
                onClick={() => setSelectedDocument(url)}
              >
                {isImage ? (
                  <img
                    src={url}
                    alt={`Vista previa ${index + 1}`}
                    style={{
                      width: size,
                      height: size,
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      fontSize: "14px",
                      textAlign: "center",
                      width: size,
                      height: size,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    .{fileExtension}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default FilePreview;
