import React, { useState } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; // Íconos de ojo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const classes = require('./CustomInput.module.css').default;

interface Props {
  control: ControllerProps['control'];
  name: string;
  placeholder?: string;
  value?: string | null;
  tipo?: string;
  label?: string;
  rules?: {};
  isDisabled?: boolean;
  isHidden?: boolean;
  showEye?: boolean; // Nuevo prop para controlar la visibilidad del ojo
  autoComplete?: string;
}

export const CustomInput = ({
  control,
  name,
  placeholder = '',
  value = '',
  tipo = 'text',
  label = '',
  rules = {},
  isDisabled = false,
  isHidden = false,
  showEye = false,
  autoComplete = 'on',
}: Props) => {
  // Estado para controlar la visibilidad de la contraseña
  const [showPassword, setShowPassword] = useState(false);

  // Función para alternar la visibilidad de la contraseña
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value === null ? '' : value}
      render={({
        field: { value, onChange, ref, onBlur },
        fieldState: { error },
      }) => (
        <div className={`row ${tipo !== 'hidden' && 'mt-3'}`}>
          {label && tipo !== 'hidden' && (
            <label
              className={`col-3 col-form-label ${classes.labelBackground}`}
              htmlFor={name}
            >
              {label}
            </label>
          )}

          <div
            className={`col-${label ? '9 ' + classes.noPadding : '12 ' + classes.noPadding}`}
          >
            <div className="input-group">
              <input
                ref={ref}
                id={name}
                placeholder={placeholder}
                type={showPassword && tipo === 'password' ? 'text' : tipo} // Cambia el tipo si es password
                disabled={isDisabled}
                className={`form-control ${
                  error === undefined ? 'is-valid' : 'is-invalid'
                }`}
                value={value}
                autoComplete={autoComplete}
                onChange={text => {
                  onChange(text);
                }}
                onBlur={onBlur}
              />
              {tipo === 'password' && showEye && ( // Solo se muestra el ojo si showEye es true
                <button
                  type="button"
                  className="input-group-text"
                  onClick={togglePasswordVisibility}
                  style={{ backgroundColor: 'transparent', border: '1px solid black' }}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              )}
            </div>
            <div className="invalid-feedback">{error?.message || 'Error'}</div>
          </div>
        </div>
      )}
    />
  );
};
