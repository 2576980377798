//React
import { useContext, useState } from "react";
//Componentes externos
import { Button, Container, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
//Componentes
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { Loading } from "../../components/Loading/Loading";
import { ModalDetalleReaccion } from "../../components/ModalDetalleReaccion/ModalDetalleReaccion";
import FilePicker from "../../components/Multimedia/FilePicker";
//import AudioRecorder from "../../components/Multimedia/AudioRecorder/AudioRecorder";
//Hooks externos
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
//Hooks
import { useMutationData } from "../../hooks/useMutationData";
import { useMutationFile } from "../../hooks/useMuationFile";
//Contexts
import { AuthContext } from "../../context/AuthContext";
//Estilo
const classes = require("./EventoCrearScreen.module.css").default;

/**
 * EventoCrearScreen
 * @description: Pantalla para crear un evento
 * @date 10/11/2023.
 * @returns JSX del Screen.
 */
export const EventoCrearScreen = () => {
  /* ----------------------------- Parámetros ------------------------------------*/
  const { reaccion_id } = useParams();
  /* ----------------------------- Hooks ------------------------------------*/
  //Hook del formulario
  const { control, handleSubmit, setValue } = useForm();
  const [modalVisible, setModalVisible] = useState(false);
  //const [audioFile, setAudioFile] = useState<File | null>(null);
  //const [isRecording, setIsRecording] = useState(false);
  const [filesLoaded, setFilesLoaded] = useState({
    images: false,
    documents: false,
    audios: false,
  });
  //Hook para realizar la petición
  const mutateEvento = useMutationData({
    url: "/evento",
    reload: ["evento", "reaccion", reaccion_id],
  });
  const mutateImages = useMutationFile({
    url: "/evento_images",
    reloads: [["reaccion", reaccion_id?.toString(), "evento"]],
  });
  const mutateDocuments = useMutationFile({
    url: "/evento_documents",
    reloads: [["reaccion", reaccion_id?.toString(), "evento"]],
  });
  const mutateAudios = useMutationFile({
    url: "/evento_audios",
    reloads: [["reaccion", reaccion_id?.toString(), "evento"]],
  });

  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  // Se obtienen los valores del usuario
  const authContext = useContext(AuthContext);
  /* ----------------------------- Funciones ----------------------------- */
  //const handleRecord = (audio: Blob) => {
  //  const file = new File([audio], "audio_recording.mp3", {
  //    type: "audio/webm",
  //  });
  //  setAudioFile(file); // Guarda el archivo en el estado
  //};

  //const handleDelete = () => {
  //  setAudioFile(null); // Elimina el archivo cuando el AudioRecorder lo notifica
  //};

  const handleFileUpload = (
    fileType: "images" | "documents" | "audios",
    files: File[]
  ) => {
    setValue(fileType, files); // Guarda los archivos en React Hook Form
    setFilesLoaded((prevState) => ({
      ...prevState,
      [fileType]: files.length > 0, // Actualiza el estado si hay archivos
    }));
  };

  // Maneja el envío del formulario
  const onSubmit = async (data: any) => {
    // Verificar si ya existe un archivo de audio y agregarlo a la lista
    //if (audioFile) {
    //  data.audios = data.audios ? [...data.audios, audioFile] : [audioFile];
    //}
    mutateEvento.mutate(
      {
        data: {
          ...data,
          usuario_id: authContext.usuario?.id,
          reaccion_id: reaccion_id,
        },
        tipo: "post",
      },
      {
        onSuccess: async (responseData) => {
          const eventId = responseData.data.data.id;

          const promises: Promise<any>[] = [];

          // Subir imágenes si existen
          if (data.images?.length > 0) {
            promises.push(
              mutateImages.mutateAsync({
                id: eventId,
                data: data.images,
                type: "post",
              })
            );
          }
          // Subir documentos si existen
          if (data.documents?.length > 0) {
            promises.push(
              mutateDocuments.mutateAsync({
                id: eventId,
                data: data.documents,
                type: "post",
              })
            );
          }
          // Subir audios si existen
          if (data.audios?.length > 0) {
            promises.push(
              mutateAudios.mutateAsync({
                id: eventId,
                data: data.audios,
                type: "post",
              })
            );
          }
          try {
            // Esperar a que todas las subidas finalicen
            await Promise.all(promises);
            navigate(`/evento/lista/${reaccion_id}`);
          } catch (error) {
            console.error("Error al subir archivos:", error);
          }
        },
      }
    );
  };

  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (
    mutateEvento.isPending ||
    mutateImages.isPending ||
    mutateDocuments.isPending ||
    mutateAudios.isPending
  ) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="p-4">
      {/* Botón para detalles de la reacción */}
      <Button
        title="Detalles de la reacción"
        className={`${classes.boton} ${classes.fixedButton}`}
        onClick={() => setModalVisible(true)}
      >
        <FontAwesomeIcon icon={faCircleInfo} />
      </Button>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            {/* Comentario */}
            <CustomTextArea
              control={control}
              name="comentario"
              label="Comentario"
              rules={{
                required: "Se requiere de un comentario",
                maxLength: {
                  value: 512,
                  message: "El comentario tiene que ser menor a 512 caracteres",
                },
              }}
            />
            {/* Coordenadas evento */}
            <CustomInput
              control={control}
              name="coordenadas_evento"
              label="Coordenadas del evento"
              rules={{
                pattern: {
                  value:
                    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
                  message:
                    "Las coordenadas del evento no tienen un formato correcto",
                },
              }}
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                }}
              >
                {/* Mostrar los pickers solo cuando no haya archivo de audio y no se esté grabando */}
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                  }}
                >
                  {/* Si hay imágenes seleccionadas, oculta el FilePicker de imágenes */}
                  {/*{!filesLoaded.documents && !isRecording && !audioFile && (*/}
                  {!filesLoaded.documents &&  (
                    <FilePicker
                      eventoId={reaccion_id ?? ""}
                      onFilesUpload={(updatedFiles) =>
                        handleFileUpload("images", updatedFiles)
                      }
                      fileType={"image"}
                    />
                  )}

                  {/* Si hay documentos seleccionados, oculta el FilePicker de documentos */}
                  {/*{!filesLoaded.images && !isRecording && !audioFile && (*/}
                  {!filesLoaded.images && (
                    <FilePicker
                      eventoId={reaccion_id ?? ""}
                      onFilesUpload={(updatedFiles) =>
                        handleFileUpload("documents", updatedFiles)
                      }
                      fileType={"document"}
                    />
                  )}
                </div>
                {/*<FilePicker
                      eventoId={reaccion_id ?? ""}
                      onFilesUpload={(updatedFiles) =>
                        handleFileUpload("audios", updatedFiles)
                      }
                      fileType={"audio"}
                    />*/}
              </div>

              {/*<div style={{ display: "flex", justifyContent: "center" }}>
                {!filesLoaded.audios &&
                  !filesLoaded.documents &&
                  !filesLoaded.images && (
                    <AudioRecorder
                      onRecord={handleRecord}
                      onRecordingStatusChange={(isRecording) =>
                        setIsRecording(isRecording)
                      } // Actualiza el estado de grabación
                      onDelete={handleDelete} // Pasamos la función de eliminación al AudioRecorder
                    />
                  )}
              </div>*/}
            </div>

            {/* Campos adicionales para roles específicos */}
            {authContext.rol === "Invitado" && (
              <>
                {/* Usuario externo id */}
                <CustomInput
                  control={control}
                  name="usuario_externo_id"
                  value={authContext.usuario?.usuario_externo_id}
                  tipo={"hidden"}
                />
                {/* Usuario externo nombre */}
                <CustomInput
                  control={control}
                  name="usuario_externo_nombre"
                  value={authContext.usuario?.usuario_externo_nombre}
                  tipo={"hidden"}
                />
              </>
            )}
            {/* Usuario id */}
            <CustomInput
              control={control}
              name="usuario_id"
              value={authContext.usuario?.id?.toString()}
              tipo={"hidden"}
            />
            {/* Reacción id */}
            <CustomInput
              control={control}
              name="reaccion_id"
              value={reaccion_id}
              tipo={"hidden"}
            />
          </div>
        </div>


        {/*{!isRecording && (
        // Botón de envío solo cuando no se está grabando
        // */}
          
          <Button className={`${classes.boton} mt-3`} type="submit">
            Guardar
          </Button>
       {/* )}*/}
      </Form>
      {/* Modal para detalles de la reacción */}
      <ModalDetalleReaccion
        show={modalVisible}
        onHide={() => setModalVisible(false)}
      />
    </Container>
  );
};
