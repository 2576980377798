//Componentes externos
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import Select from 'react-select';
//Estilos
const classes = require('./CustomSelect.module.css').default;
//Props
interface CustomSelectProps<TFormValues extends FieldValues, TData> {
  //Nombre de la variable
  name: Path<TFormValues>;
  //Valor del label
  label?: string;
  //Valor del placeholder
  placeholder?: string;
  //Valor por default del input
  defaultValue?: PathValue<TFormValues, Path<TFormValues>>;
  //Control del formulario
  control: Control<TFormValues>;
  //Reglas para validar el input
  rules?: {};
  //Variable pare dar las opciones
  data: TData[] | undefined | null;
  //Si está habilitado o no
  isDisabled?: boolean;
  //Si se puede limpiar o no
  isClearable?: boolean;
  //Nombre del campo donde obtener el label
  labelField: keyof TData;
  //Nombre del campo donde obtener el value
  valueField: keyof TData;
  //Se establece el formato de la selección
  returnFormat?: 'value' | 'object' | 'label';
}

/**
 * CustomSelect Component
 * @description: Component que se encarga de manejar el select con ayuda de la librería react hook form
 * @date 01/10/2023.
 * @param Props Recibe data necesaria a utilizar.
 * @returns JSX de la tabla.
 */
export const CustomSelect = <TFormValues extends FieldValues, TData>({
  name,
  label,
  placeholder,
  defaultValue,
  control,
  rules = {},
  data,
  isDisabled = false,
  isClearable = true,
  labelField,
  valueField,
  returnFormat = 'value',
}: CustomSelectProps<TFormValues, TData>) => {
  //Se mapea la información
  const options = data?.map(element => {
    return { label: element[labelField], value: element[valueField] };
  });
  /* ------------------------------ Funciones -------------------------------*/
  //Función para encontrar en la data el valor default
  const findFormatDefaultValue = (
    value: PathValue<TFormValues, Path<TFormValues>> | undefined
  ): Record<string, any> | undefined => {
    if (returnFormat === 'label') {
      return options?.find(option => option['label'] === value);
    } else {
      return options?.find(option => option['value'] === Number(value));
    }
  };
  /* -------------------------------- return --------------------------------*/
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, ref, onBlur },
        fieldState: { error },
      }) => (
        <div className="row mt-3">
          {label && (
            <span className={`col-3 col-form-label ${classes.labelBackground}`}>
              {label}
            </span>
          )}
          <div
            className={`col-${
              label ? '9 ' + classes.noPadding : '12 ' + classes.noPadding
            }`}
          >
            <Select
              id={name}
              ref={ref}
              placeholder={placeholder}
              isClearable={isClearable}
              isSearchable={true}
              isDisabled={isDisabled}
              defaultValue={findFormatDefaultValue(value)}
              options={options}
              onBlur={onBlur}
              className={`form-control ${
                error === undefined ? 'is-valid' : 'is-invalid'
              } `}
              styles={{
                menu: (base) => ({
                  ...base,
                  zIndex: 9999, // Asegúrate de que el menú esté en la parte superior
                }),
              }}
              onChange={val => {
                //Acción cuando se resetea el select
                if (!val) {
                  val = {
                    label: '',
                    value: undefined,
                  };
                }
                if (returnFormat === 'object') {
                  onChange(val);
                } else if (returnFormat === 'value') {
                  onChange(val.value);
                } else {
                  onChange(val.label);
                }
              }}
            />
            <div className="invalid-feedback">{error?.message || 'Error'}</div>
          </div>
        </div>
      )}
    />
  );
};
