import React, { useEffect, useRef, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  //faDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "./FileModal.css";
//import axios from "axios";
//import { API } from "../../../global/global";
//import { toast } from "react-toastify";

interface FileModalProps {
  selectedDocument: string | null;
  previewUrls: string[];
  setSelectedDocument: React.Dispatch<React.SetStateAction<string | null>>;
  onRemoveDocument?: (url: string) => void;
  files: { file: File; url: string }[];
  isVisible?: boolean; // Propiedad opcional para controlar la visibilidad
}

const FileModal: React.FC<FileModalProps> = ({
  selectedDocument,
  previewUrls,
  setSelectedDocument,
  onRemoveDocument,
  files,
  isVisible = true,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const mediaRef = useRef<HTMLImageElement | null>(null);
  const [canZoom, setCanZoom] = useState<boolean>(false);
  const currentIndex = selectedDocument
    ? previewUrls.indexOf(selectedDocument)
    : -1;

  const goToNextDocument = useCallback(
    (e: React.MouseEvent | null = null) => {
      if (e) e.stopPropagation(); // Evita que el evento se propague en el caso del clic
      setSelectedDocument(previewUrls[(currentIndex + 1) % previewUrls.length]);
    },
    [currentIndex, previewUrls, setSelectedDocument] // Añadir setSelectedDocument aquí
  );

  const goToPreviousDocument = useCallback(
    (e: React.MouseEvent | null = null) => {
      if (e) e.stopPropagation(); // Evita que el evento se propague en el caso del clic
      setSelectedDocument(
        previewUrls[
          (currentIndex - 1 + previewUrls.length) % previewUrls.length
        ]
      );
    },
    [currentIndex, previewUrls, setSelectedDocument] // Añadir setSelectedDocument aquí
  );

  useEffect(() => {
    if (!selectedDocument) {
      setCanZoom(false);
      return;
    }

    const img = new Image();
    img.src = selectedDocument;

    img.onload = () => {
      const { width, height } = img;
      const averageSize = (width + height) / 2;

      // Activa el zoom solo si la imagen cumple con el tamaño promedio
      setCanZoom(averageSize >= 500);
    };

    img.onerror = () => {
      setCanZoom(false); // Si la imagen falla al cargar
    };
  }, [selectedDocument]);

  if (!selectedDocument || !isVisible) return null;

  const closeModal = () => {
    setSelectedDocument(null);
    setZoomLevel(1);
    setPosition({ x: 0, y: 0 });
  };

  const deleteDocument = () => {
    if (selectedDocument && onRemoveDocument) {
      onRemoveDocument(selectedDocument);
    }
  };

  const toggleZoom = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (canZoom) {
      setZoomLevel(zoomLevel === 1 ? 2 : 1);
      if (zoomLevel === 2) setPosition({ x: 0, y: 0 });
    }
  };

  const moveImage = (e: React.MouseEvent) => {
    if (zoomLevel === 1) return;

    const media = mediaRef.current;
    if (media && media instanceof HTMLImageElement) {
      const rect = media.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;

      const sensitivity = 0.5; // Ajusta la sensibilidad
      let moveX = -(offsetX / rect.width - 0.5) * 100 * sensitivity; // Negar para invertir
      let moveY = -(offsetY / rect.height - 0.5) * 100 * sensitivity; // Negar para invertir

      // Calcula los límites basados en el nivel de zoom
      const maxMoveX = (zoomLevel - 1) * 50; // Límite horizontal
      const maxMoveY = (zoomLevel - 1) * 50; // Límite vertical

      // Aplica los límites
      moveX = Math.max(-maxMoveX, Math.min(maxMoveX, moveX));
      moveY = Math.max(-maxMoveY, Math.min(maxMoveY, moveY));

      setPosition({ x: moveX, y: moveY });
    }
  };

  const getFileType = (url: string) => {
    const file = files.find((f) => f.url === url);
    if (file) {
      const extension = file.file.name.split(".").pop()?.toUpperCase();
      return extension ? extension : "Unknown";
    }
    return "Unknown";
  };

  const getFileName = (url: string) => {
    const file = files.find((f) => f.url === url);

    if (file) {
      // Obtén solo el nombre del archivo eliminando la ruta
      const fullName = file.file.name.split("/").pop() ?? file.file.name;

      // Elimina el prefijo del nombre del archivo
      const cleanedName = fullName.replace(/^\d+_\w+_/, ""); // Elimina "123456_abc123_"
      return cleanedName;
    }
    return "Unknown file";
  };

  //const getFileNameDetails = (url: string) => {
  //  const file = files.find((f) => f.url === url);
  //  const fullName = file?.file.name;
//
  //  // Verifica si se encontró el archivo y si su nombre tiene la estructura correcta
  //  if (fullName) {
  //    // Dividimos el nombre del archivo en partes utilizando '/'
  //    const urlParts = fullName.split("/");
//
  //    // Asumimos que la estructura es 'eventoId/type/fileName'
  //    const eventoId = urlParts[0]; // Primer parte: eventoId
  //    const type = urlParts[1]; // Segunda parte: tipo
  //    const fileName = urlParts[2]; // Tercera parte: nombre
//
  //    // Retornar el formato solicitado
  //    return { eventoId, type, fileName };
  //  } else {
  //    console.error("Archivo no encontrado o nombre no válido");
  //    return { eventoId: "", type: "", fileName: "" };
  //  }
  //};

  const renderDocument = (url: string) => {
    const file = files.find((f) => f.url === url);
    if (!file) return null;

    const fileExtension = getFileType(url).toUpperCase();

    if (fileExtension.match(/^JPG|JPEG|PNG|GIF|WEBP|SVG$/)) {
      return (
        <img
          ref={mediaRef}
          src={url}
          alt={getFileName(url)}
          style={{
            maxWidth: "50%",
            maxHeight: "400px",
            objectFit: "contain",
            position: "relative",
            bottom: "50px",
            transform: `scale(${zoomLevel}) translate(${position.x}%, ${position.y}%)`,
            cursor: zoomLevel > 1 ? "zoom-out" : "zoom-in",
          }}
          onClick={toggleZoom}
          onMouseMove={moveImage}
        />
      );
    }

    if (fileExtension.match(/^MP3|WAV|OGG|FLAC|AAC|M4R|MIDI|M4A$/)) {
      return (
        <audio controls src={url} style={{ width: "50%" }}>
          Tu navegador no soporta la etiqueta de audio.
        </audio>
      );
    }

    if (fileExtension.match(/^MP4|AVI|MKV|MOV|WEBM|FLV|MPEG|OGV$/)) {
      return (
        <audio controls src={url} style={{ width: "50%" }}>
          Tu navegador no soporta la etiqueta de audio.
        </audio>
      );
    }

    if (fileExtension === "PDF") {
      return (
        <iframe
          src={url}
          style={{
            width: "50%",
            height: "400px",
            position: "relative",
            bottom: "50px",
          }}
          title="PDF Preview"
        >
          Este navegador no soporta PDFs.
        </iframe>
      );
    }

    return (
      <div
        style={{
          width: "10%",
          height: "80px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#222",
          color: "white",
          borderRadius: "5px",
          fontSize: "18px",
          textAlign: "center",
        }}
      >
        <div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              backgroundColor: "#444",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
          >
            {fileExtension}
          </div>
        </div>
      </div>
    );
  };

  //const handleDownload = async (event: { stopPropagation: () => void }) => {
  //  event.stopPropagation();
//
  //  if (selectedDocument) {
  //    try {
  //      const token = localStorage.getItem("token");
  //      const { eventoId, type, fileName } =
  //        getFileNameDetails(selectedDocument);
//
  //      const response = await axios.get(
  //        `${API}/download/${eventoId}/${type}/${fileName}`,
  //        {
  //          headers: {
  //            Authorization: `Bearer ${token}`,
  //          },
  //          responseType: "blob", // Importante para manejar archivos binarios
  //        }
  //      );
//
  //      const blob = new Blob([response.data], {
  //        type: response.headers["content-type"],
  //      });
  //      const link = document.createElement("a");
  //      link.href = URL.createObjectURL(blob);
  //      link.download = fileName; // Usa el nombre del archivo extraído
  //      link.click();
//
  //      // Mostrar un toast si la respuesta fue exitosa
  //      if (response.status === 200) {
  //        toast.success("¡Archivo descargado exitosamente!");
  //      }
  //    } catch (error) {
  //      console.error("Error descargando el archivo:", error);
  //      toast.error("Hubo un problema al descargar el archivo.");
  //    }
  //  }
  //};

  return (
    <div className="document-modal" onClick={closeModal}>

      {selectedDocument && renderDocument(selectedDocument)}
      {zoomLevel === 1 && onRemoveDocument && (
        <>
          <button
            className="delete-button"
            type="button"
            onClick={deleteDocument}
            title="Eliminar"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      )}

      {zoomLevel === 1 && (
        <div className="document-counter">
          {currentIndex + 1} de {previewUrls.length}
        </div>
      )}

      {/* Mostrar botones de navegación solo cuando no haya zoom */}
      {zoomLevel === 1 && previewUrls.length > 1 && (
        <>
          <button
            className="prev-button"
            type="button"
            title="Anterior"
            onClick={goToPreviousDocument}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            className="next-button"
            type="button"
            title="Siguiente"
            onClick={goToNextDocument}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </>
      )}

      {/* Mostrar miniaturas solo cuando no haya zoom */}
      {zoomLevel === 1 && (
        <div
          className={`thumbnails ${previewUrls.length > 8 ? "scrollable" : ""}`}
        >
          {previewUrls.map((url, index) => (
            <div
              title={getFileName(url)}
              key={index}
              className={`thumbnail ${
                selectedDocument === url ? "selected" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedDocument(url);
              }}
            >
              {getFileType(url).match(/^JPG|JPEG|PNG|GIF|WEBP|SVG$/) ? (
                <img
                  src={url}
                  alt={getFileName(url)}
                  style={{
                    width: "45px", // Tamaño de la miniatura
                    height: "45px", // Tamaño de la miniatura
                    objectFit: "cover", // Recortar la imagen si es necesario
                  }}
                />
              ) : (
                <span>{getFileType(url)}</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileModal;
